import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField, Box, Divider, CircularProgress, Pagination, List, ListItem, ListItemText, Card, CardContent, Typography, Dialog, DialogTitle, IconButton, DialogContent, Stack, Chip, ButtonGroup, CardActions } from '@mui/material';
import { Add, AutoAwesome, Close, Edit, ErrorOutline, MailOutline, MenuBook, Science, Search } from '@mui/icons-material';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';
import { LoadingButton } from '@mui/lab';
import ArticleIcon from '@mui/icons-material/Article';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useNewPublicationContext } from '../../context/NewPublicationProvider';
import ScopusIcon from '../../assets/schopus.png'
import GoogleIcon from '../../assets/googleSchooler.png'
import { Link } from 'react-router-dom';
import SelectPublicationType from './SelectPublicationType';
const baseUrl = process.env.REACT_APP_BASE_URL;
const publicationsType = [
    { id: 0, key: "Article", value: "Article", icon: <ArticleIcon /> },
    { id: 1, key: "Thesis", value: "Thesis", icon: <SchoolIcon /> },
    { id: 2, key: "Chapter in a book", value: "Chapter in a book", icon: <BookIcon /> },
    { id: 3, key: "Conference paper", value: "Conference paper", icon: <AssignmentIcon /> },
    { id: 4, key: "Research project", value: "Research project", icon: <Science /> },
    { id: 5, key: "Company test report", value: "Company test report", icon: <BusinessCenterIcon /> },
    { id: 6, key: "Book", value: "Book", icon: <MenuBook /> },
    { id: 7, key: "Letter", value: "Letter", icon: <MailOutline /> },
    { id: 8, key: "Retracted Article", value: "Retracted Article", icon: <ErrorOutline /> },  // Letter icon added here
    { id: 9, key: "Editorial Material", value: "Editorial Material", icon: <Edit /> }  // Letter icon added here
];
const SearchAutoPublication = () => {
    const [searchType, setSearchType] = useState('ALL');
    const { setStep, updateData, updateType, publication } = useNewPublicationContext();
    const [searchValue, setSearchValue] = useState('');
    const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [publications, setPublications] = useState([]);
    const [publicationsGoogle, setPublicationsGoogle] = useState([]);
    const [autoPublications, setAutoPublications] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const { token, user } = useUserContext();
    const [autotype, setAutoType] = useState("scopus")
    const [selectType, setSelectType] = useState(null)
    const [selectGooglePublication, setSelectGooglePublication] = useState(null)
    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSearchValue(''); // Clear search input when search type changes
    };
    const cleanText = (text = "") => {
        // Regular expression to remove all tags (anything inside <>)
        return text.replace(/<[^>]*>/g, '').trim();
    };



    function getYear(dateStr) {
        if (dateStr) {
            const date = new Date(dateStr);

            // Yılı döndür
            return date.getFullYear();
        } else {
            return null
        }
        // Tarih dizesini Date objesine dönüştür

    }
    const handleSearchValueChange = (event) => {
        setSearchValue(event.target.value);
    };
    const getAuthHeaders = (token) => {
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    const buildQuery = () => {
        return searchType === 'ALL' ? `ALL(${searchValue})` : searchType == 'DOI' ? `DOI(${searchValue}) ` : `AU-ID(${searchValue})`;
    };

    const convertReferencesToString = (references) => {
        if (!Array.isArray(references) || references.length === 0) {
            return "No references available.";
        }

        try {
            const processedReferences = references.map((reference) => {
                if (reference["DOI"]) {
                    // Extract data with fallback to "N/A"
                    const author = reference.author || "N/A";
                    const year = reference.year || "N/A";
                    const title = reference["article-title"] || "N/A";
                    const journalName = reference["journal-title"] || "N/A";
                    const volume = reference["volume"] || "N/A";
                    const issue = reference["issue"] || "N/A";
                    const doi = reference["DOI"];

                    // Format and return
                    return `${author} (${year}). ${title}. ${journalName}, ${volume}(${issue}). DOI: ${doi}.`;
                } if (reference["article-title"]) {
                    // Extract data with fallback to "N/A"
                    const author = reference.author || "N/A";
                    const year = reference.year || "N/A";
                    const title = reference["article-title"] || "N/A";
                    const journalName = reference["journal-title"] || "N/A";
                    const volume = reference["volume"] || "N/A";
                    const issue = reference["issue"] || "N/A";

                    // Format and return
                    return `${author} (${year}). ${title}. ${journalName}, ${volume}(${issue}).`;
                }
                else if (reference["series-title"]) {
                    const author = reference.author || "N/A";
                    const year = reference.year || "N/A";
                    const seriesTitle = reference["series-title"] || "N/A";
                    const articleTitle = reference["article-title"] || "";

                    // Format and return
                    return `${author} (${year}). ${articleTitle} ${seriesTitle}`;
                }
                else if (reference["unstructured"]) {
                    // Return unstructured reference
                    return reference["unstructured"];
                }
                else {
                    // Missing DOI or other required fields
                    return "Missing DOI.";
                }
            });

            // Combine all references into a single string
            return processedReferences.join("\n\n");
        } catch (error) {
            return "An error occurred.";
        }
    };

    const handleAddGoogle = async (link) => {
        setLoading(true); // Start loading
        try {
            let response;

            if (token) {
                response = await axios.get(`${baseUrl}/api/v1/google/get-by-link`, {
                    params: {
                        link: link
                    },
                    headers: getAuthHeaders(token)
                });

            }
            setSelectType(response.data[0].publication_type)
            setSelectGooglePublication(response.data[0])
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching auto publications:', error);
            setAutoPublications([]); // Clear auto publications in case of error
        } finally {
            setLoading(false); // End loading
        }
    }

    const handleAutoAdd = async (data) => {
        console.log("Input Data:", data);
        handleDialogClose()

        // Start loading
        setLoading(true);

        // Check if DOI is provided
        if (!data["prism:doi"]) {
            console.error("DOI is missing.");
            setLoading(false); // Stop loading
            return; // Exit early
        }

        try {
            // Fetch publication details from CrossRef API
            const response = await axios.get(`https://api.crossref.org/works/${data["prism:doi"]}`);
            const publicationData = response.data.message;

            console.log("Fetched Publication Data:", publicationData);
            console.log(data.subtypeDescription)
            // Check publication subtype
            if (data.subtypeDescription == "Conference Paper" || data.subtypeDescription == "Review" || data.subtypeDescription == "Article") {
                console.log("Tıklandı")
                // Update type and step
                updateType("Article");
                setStep(1);
                const shortContainerTitle = publicationData["short-container-title"] || data["prism:publicationName"]
                console.log(response.data)
                // Update publication data
                updateData({
                    title: publicationData.title[0],
                    journalName: data["prism:publicationName"] || shortContainerTitle,
                    volume: publicationData.volume,
                    issue: publicationData.issueIdentifier || publicationData.issue || "N/A",
                    pages: data["prism:pageRange"] || publicationData.page,
                    doi: publicationData.DOI,
                    linkOfThePaper: publicationData.URL,
                    comment: cleanText(publicationData.abstract || response.data.abstract || data.abstract || "No abstract available."),
                    indexing: '',
                    language: '',
                    references: convertReferencesToString(publicationData.reference),
                    year: getYear(data["prism:coverDate"])
                });
            } else if (data.subtypeDescription == "Letter") {
                console.log("Tıklandı")
                // Update type and step
                updateType("Letter");
                setStep(1);
                const shortContainerTitle = publicationData["short-container-title"] || data["prism:publicationName"]

                // Update publication data
                updateData({
                    title: publicationData.title[0],
                    journalName: data["prism:publicationName"] || shortContainerTitle,
                    volume: publicationData.volume,
                    issue: publicationData.issueIdentifier || publicationData.issue || "N/A",
                    pages: data["prism:pageRange"] || publicationData.page,
                    doi: publicationData.DOI,
                    linkOfThePaper: publicationData.URL,
                    comment: cleanText(publicationData.abstract || data.abstract || "No abstract available."),
                    indexing: '',
                    language: '',
                    references: convertReferencesToString(publicationData.reference),
                    year: getYear(data["prism:coverDate"])
                });
            } else if (data.subtypeDescription == "Book") {
                console.log("Tıklandı")
                // Update type and step
                updateType("Book");
                setStep(1);
                const shortContainerTitle = publicationData["short-container-title"]

                // Update publication data
                updateData({
                    title: publicationData.title[0],
                    coEditors: '',
                    edition: '',
                    year: getYear(data["prism:coverDate"]),
                    pages: data["prism:pageRange"] || publicationData.page,
                    doi: publicationData.DOI,
                    publisher: publicationData.publisher,
                    isbn: publicationData.ISBN || publicationData.isbn || "No isbn available.",
                    editor: '',
                    comment: cleanText(publicationData.abstract || "No abstract available."),
                    references: convertReferencesToString(publicationData.reference),
                    linkOfTheBook: publicationData.URL,
                    language: '',
                    indexing: ''
                });
            } else if (data.subtypeDescription == "Book Chapter") {
                console.log("Tıklandı")
                // Update type and step
                updateType("Chapter in a book");
                setStep(1);
                const shortContainerTitle = publicationData["short-container-title"]

                // Update publication data
                updateData({
                    title: publicationData.title[0],
                    chapterNumber: '',
                    bookTitle: '',
                    year: getYear(data["prism:coverDate"]),
                    pages: data["prism:pageRange"] || publicationData.page,
                    doi: publicationData.DOI,
                    publisher: publicationData.publisher,
                    isbn: publicationData.ISBN || publicationData.isbn || "No isbn available.",
                    editor: '',
                    comment: cleanText(publicationData.abstract || "No abstract available."),
                    references: convertReferencesToString(publicationData.reference),
                    linkOfTheChapter: publicationData.URL,
                    language: ''
                });
            } else {
                console.warn("Unsupported publication subtype:", publicationData.subtypeDescription);
            }
        } catch (error) {
            console.error("Error fetching publication data:", error);
        } finally {
            // Stop loading
            setLoading(false);
        }
    };
    const endSave = async () => {
        let publication;
        if (selectType == "Article") {
            publication = {
                title: selectGooglePublication?.title || '',
                journalName: selectGooglePublication?.journal || '',
                volume: selectGooglePublication?.volume || '',
                issue: selectGooglePublication?.ıssue || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                linkOfThePaper: selectGooglePublication?.link || '',
                comment: selectGooglePublication?.description || '',
                indexing: '',
                language: '',
                references: '',
                year: selectGooglePublication?.["publication date"]?.split("/")?.[0] || ''
            };
        } else if (selectType == "Book") {
            publication = {
                title: selectGooglePublication?.title || '',
                coEditors: '',
                edition: '',
                year: selectGooglePublication?.["publication date"]?.split("/")?.[0] || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                publisher: selectGooglePublication?.publisher || '',
                isbn: '',
                editor: '',
                comment: selectGooglePublication?.description || '',
                references: '',
                linkOfTheBook: selectGooglePublication?.link || '',
                language: '',
                indexing: ''
            }
        } else if (selectType == "Chapter in a book") {
            publication = {
                title: selectGooglePublication?.title || '',
                chapterNumber: '',
                bookTitle: '',
                year: selectGooglePublication?.["publication date"]?.split("/")?.[0] || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                publisher: selectGooglePublication?.publisher || '',
                isbn: '',
                editor: '',
                comment: selectGooglePublication?.description || '',
                references: '',
                linkOfTheChapter: selectGooglePublication?.link || '',
                language: ''
            }
        } else if (selectType == "Company test report") {
            publication = {
                title: selectGooglePublication?.title || '',
                date: selectGooglePublication?.["publication date"]?.split("/") || '',
                companyName: '',
                comment: selectGooglePublication?.description || '',
                references: '',
                language: ''
            }
        } else if (selectType == "Conference paper") {
            publication = {
                title: selectGooglePublication?.title || '',
                date: selectGooglePublication?.["publication date"]?.split("/") || '',
                conferenceName: '',
                cityCountry: '',
                pages: selectGooglePublication?.pages || '',
                isbn: '',
                comment: selectGooglePublication?.description || '',
                references: '',
                language: '',
                conferenceType: '',
                linkOfTheConference: selectGooglePublication?.link || ''
            }
        } else if (selectType == "Research project") {
            publication = {
                title: selectGooglePublication?.title || '',
                beginningDate: '',
                completedDate: '',
                universityOrCompany: selectGooglePublication?.ınstitution,
                grantNumber: '',
                comment: selectGooglePublication?.description || '',
                references: '',
                language: ''
            }
        } else if (selectType == "Thesis") {
            publication = {
                title: selectGooglePublication?.title || '',
                degree: '',
                university: selectGooglePublication?.ınstitution,
                pages: selectGooglePublication?.pages || '',
                month: '',
                department: '',
                comment: selectGooglePublication?.description || '',
                language: '',
                references: ''
            }
        } else if (selectType == "Letter") {
            publication = {
                title: selectGooglePublication?.title || '',
                journalName: selectGooglePublication?.journal || '',
                volume: selectGooglePublication?.volume || '',
                issue: selectGooglePublication?.ıssue || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                linkOfThePaper: selectGooglePublication?.link || '',
                comment: selectGooglePublication?.description || '',
                indexing: '',
                language: '',
                references: '',
                year: selectGooglePublication?.["publication date"]?.split("/")?.[0] || '',
                commentType: ''
            }
        } else if (selectType == "Retracted Article") {
            publication = {
                title: selectGooglePublication?.title || '',
                journalName: selectGooglePublication?.journal || '',
                volume: selectGooglePublication?.volume || '',
                issue: selectGooglePublication?.ıssue || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                linkOfThePaper: selectGooglePublication?.link || '',
                comment: selectGooglePublication?.description || '',
                indexing: '',
                language: '',
                references: '',
            }
        } else if (selectType == "Editorial Material") {
            publication = {
                title: selectGooglePublication?.title || '',
                journalName: selectGooglePublication?.journal || '',
                volume: selectGooglePublication?.volume || '',
                issue: selectGooglePublication?.ıssue || '',
                pages: selectGooglePublication?.pages || '',
                doi: '',
                linkOfThePaper: selectGooglePublication?.link || '',
                comment: selectGooglePublication?.description || '',
                indexing: '',
                language: '',
                references: '',
                year: selectGooglePublication?.["publication date"]?.split("/")?.[0] || '',
                commentType: ''
            }
        }
        updateType(selectType);
        setStep(1);
        updateData(publication)
    }

    const handleSearch = () => {
        if (!searchValue.trim()) {
            alert('Please enter a search value.');
            return;
        }

        setLoading(true);
        setCurrentPage(1);
        setPublications([]); // Clear previous search results

        axios
            .get(`${baseUrl}/api/v1/no-auth/scopus/publications`, {
                params: { query: buildQuery(), startIndex: 0, itemsPerPage },
            })
            .then((response) => {
                if (response.data && response.data['search-results']) {
                    const searchResults = response.data['search-results'];

                    // Extract items and totalResults from the response
                    const publications = searchResults.entry || [];
                    const totalResults = parseInt(searchResults['opensearch:totalResults'], 10) || 0;

                    setPublications(publications); // Set the publications list
                    setTotalResults(totalResults); // Set the total results count

                    console.log(response.data)
                } else {
                    console.error('Unexpected response structure:', response.data);
                    alert('Unexpected response format');
                }
                setLoading(false);
            })
            .catch(handleSearchError);
    };

    const repateSearch = (page = currentPage) => {
        if (!searchValue.trim()) {
            alert('Please enter a search value.');
            return;
        }

        // Prepare the query based on the search type
        const query = searchType === 'ALL' ? `ALL(${searchValue})` : `AU-ID(${searchValue})`;

        setLoading(true);

        // Send the GET request to the backend API with pagination params
        axios
            .get(`${baseUrl}/api/v1/no-auth/scopus/publications`, {
                params: { query, startIndex: (page - 1) * itemsPerPage, itemsPerPage },
            })
            .then((response) => {
                if (response.data && response.data['search-results']) {
                    const searchResults = response.data['search-results'];

                    // Extract items and totalResults from the response
                    const publications = searchResults.entry || [];
                    const totalResults = parseInt(searchResults['opensearch:totalResults'], 10) || 0;

                    setPublications(publications); // Set the publications list
                    setTotalResults(totalResults); // Set the total results count

                    console.log(response.data)
                } else {
                    console.error('Unexpected response structure:', response.data);
                    alert('Unexpected response format');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching publications:', error);
                setLoading(false);
                alert('Error fetching publications');
            });
    };
    const handleDialogOpen = (type) => {
        setAutoType(type)
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setSelectType(null)
        setSelectGooglePublication(null)
        setOpenDialog(false);
    };


    const handleSearchResults = (response) => {
        if (response.data && response.data['search-results']) {
            const searchResults = response.data['search-results'];
            const publications = searchResults.items || [];
            const totalResults = parseInt(searchResults['opensearch:totalResults'], 10) || 0;

            setPublications(publications);
            setTotalResults(totalResults);
        } else {
            console.error('Unexpected response structure:', response.data);
            alert('Unexpected response format');
        }
        setLoading(false);
    };

    const handleSearchError = (error) => {
        console.error('Error fetching publications:', error);
        setLoading(false);
        alert('Error fetching publications');
    };

    const handlePageChange = (event, newPage) => {
        // Set the current page based on the selected page
        setCurrentPage(newPage);
        // Trigger search with the selected page (newPage)
        repateSearch(newPage);
    };

    const handleAdvancedSearchToggle = (searchMode) => {
        setIsAdvancedSearch(searchMode === 'Advanced Search');
        setSearchValue(''); // Clear search value when switching to advanced search
    };
    useEffect(() => {
        fetchPublicationAuto();
    }, []);
    const fetchPublicationAuto = async () => {
        setLoading(true); // Start loading for Scopus


        try {
            if (token) {
                const response = await axios.get(`${baseUrl}/api/v1/scopus/publication/by-author-id`, {
                    headers: getAuthHeaders(token),
                });
                const searchResults = response.data['search-results'];
                const publications = searchResults.entry || [];
                setAutoPublications(publications); // Set the Scopus publications data
            }
        } catch (error) {
            console.error('Error fetching Scopus publications:', error);
            setAutoPublications([]); // Clear Scopus publications in case of error
        } finally {
            setLoading(false); // End loading for Scopus
        }
        setLoading(true);
        try {
            if (token) {
                const response = await axios.get(`${baseUrl}/api/v1/google/all`, {
                    headers: getAuthHeaders(token),
                });
                console.log(response)
                setPublicationsGoogle(response.data); // Set the Google Scholar publications data
            }
        } catch (error) {
            console.error('Error fetching Google Scholar publications:', error);
            setPublicationsGoogle([]); // Clear Google Scholar publications in case of error
        } finally {
            setLoading(false); // End loading for Google Scholar
        }
    };
    return (
        <Box sx={{ padding: 2, borderRadius: 2, backgroundColor: '#f4f6f8' }}>
            <Grid container spacing={1} alignItems="center">

                <ButtonGroup>
                    {loading ? <CircularProgress /> : !loading && autoPublications.length > 0 ? <Box>
                        <Button
                            onClick={() => handleDialogOpen("scopus")}
                            variant='text'
                            size='small'
                            startIcon={<img src={ScopusIcon} alt="Scopus" style={{ width: 20, height: 20 }} />}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            {autoPublications.length > 0 &&
                                <Chip sx={{ bgcolor: "white", marginLeft: 1 }} label={autoPublications.length} />}
                            Scopus papers found.
                            {loading && <CircularProgress size={24} sx={{ ml: 2, color: 'white' }} />}
                        </Button>
                    </Box> : (!loading && <Typography color='red' component={Link} to={`/researcher/${user.id}`}>You may not have added the Scopus link. If you add the Scopus link, you can get more accurate results in finding your paper.</Typography>)}
                    {!loading && publicationsGoogle.length > 0 ? <Box>
                        <Button
                            onClick={() => handleDialogOpen("scholar")}
                            variant='text'
                            size='small'
                            startIcon={<img src={GoogleIcon} style={{ width: 20, height: 20 }} />}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            {publicationsGoogle.length > 0 &&
                                <Chip sx={{ bgcolor: "white", marginLeft: 1 }} label={publicationsGoogle.length} />}
                            Google Scholar papers found.
                            {loading && <CircularProgress size={24} sx={{ ml: 2, color: 'white' }} />}
                        </Button>
                    </Box> : (!loading && <Typography color='red' component={Link} to={`/researcher/${user.id}`}>You may not have added the Google scholar link. If you add the  Google scholar link, you can get more accurate results in finding your paper.</Typography>)}
                </ButtonGroup>


                {/* <Grid item xs={6} sm={4} md={3}>
                    <Button
                        size="small"
                        variant={isAdvancedSearch ? 'outlined' : 'contained'}
                        color="secondary"
                        fullWidth
                        onClick={() => handleAdvancedSearchToggle('Basic Search')}
                    >
                        Basic Search
                    </Button>
                </Grid> */}
                {/* <Grid item xs={6} sm={4} md={3}>
                    <Button
                        size="small"
                        variant={isAdvancedSearch ? 'contained' : 'outlined'}
                        color="secondary"
                        fullWidth
                        onClick={() => handleAdvancedSearchToggle('Advanced Search')}
                    >
                        Advanced Search
                    </Button>
                </Grid> */}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>

            {/* Conditional rendering based on search mode */}
            <Grid container marginTop={2} spacing={1} alignItems="center">
                {isAdvancedSearch ? (
                    <AdvancedSearch
                        searchType={searchType}
                        searchValue={searchValue}
                        onSearchTypeChange={handleSearchTypeChange}
                        onSearchValueChange={handleSearchValueChange}
                        onSearch={handleSearch}
                    />
                ) : (
                    <BasicSearch
                        searchValue={searchValue}
                        onSearchValueChange={handleSearchValueChange}
                        onSearch={handleSearch}
                        loading={loading}
                    />
                )}
            </Grid>

            {/* Results Display */}
            <Grid container spacing={2} marginTop={3}>
                <List>
                    {
                        publications.map((item, index) => (
                            <ListItem key={index} sx={{ marginBottom: 2 }}>
                                <Card sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                            component="div"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {item["dc:title"] || 'No Title Available'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 1 }}
                                        >
                                            <strong>Author:</strong> {item["dc:creator"] || 'Unknown Author'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Publication:</strong>{' '}
                                            {item["prism:publicationName"] || 'No Publication Name'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Publication Type:</strong>{' '}
                                            {item["subtypeDescription"] || 'Type'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>DOI:</strong> {item["prism:doi"] || 'No DOI Available'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Published in:</strong>{' '}
                                            {item["prism:coverDisplayDate"] || 'No Date Available'}
                                        </Typography>
                                        <Divider sx={{ marginTop: 1 }} />
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 1 }}
                                        >
                                            <strong>Affiliation:</strong>{' '}
                                            {item.affiliation?.[0]?.affilname || 'No Affiliation Available'}{' '}
                                            - {item.affiliation?.[0]?.["affiliation-city"] || 'Unknown City'},{' '}
                                            {item.affiliation?.[0]?.["affiliation-country"] || 'Unknown Country'}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                            <Button
                                                startIcon={<Add />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAutoAdd(item)}
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: '8px',
                                                    padding: '6px 16px',
                                                    boxShadow: 2,
                                                }}
                                            >
                                                I am the author of this study.
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>


                        ))
                    }
                </List>
            </Grid>

            {/* Pagination */}
            <Grid container justifyContent="center" marginTop={3}>
                <Pagination
                    count={Math.ceil(totalResults / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                />
            </Grid>
            <Dialog
                maxWidth="md"
                fullWidth
                open={openDialog}
                onClose={handleDialogClose}
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '12px', // Rounded corners for a modern look
                        padding: '16px', // Padding for extra space inside the dialog
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold' }}>
                    <Typography variant="h6">Auto New Publications</Typography>

                    <IconButton onClick={handleDialogClose} color="inherit">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List sx={{ padding: 0 }}>
                        {autotype == "scopus" ? (autoPublications.length > 0 && autoPublications.map((item, index) => (
                            <ListItem key={index} sx={{ marginBottom: 2 }}>
                                <Card sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                            component="div"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {item["dc:title"] || 'No Title Available'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 1 }}
                                        >
                                            <strong>Author:</strong> {item["dc:creator"] || 'Unknown Author'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Publication:</strong>{' '}
                                            {item["prism:publicationName"] || 'No Publication Name'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Publication Type:</strong>{' '}
                                            {item["subtypeDescription"] || 'Type'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>DOI:</strong> {item["prism:doi"] || 'No DOI Available'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 0.5 }}
                                        >
                                            <strong>Published in:</strong>{' '}
                                            {item["prism:coverDisplayDate"] || 'No Date Available'}
                                        </Typography>
                                        <Divider sx={{ marginTop: 1 }} />
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ marginTop: 1 }}
                                        >
                                            <strong>Affiliation:</strong>{' '}
                                            {item.affiliation?.[0]?.affilname || 'No Affiliation Available'}{' '}
                                            - {item.affiliation?.[0]?.["affiliation-city"] || 'Unknown City'},{' '}
                                            {item.affiliation?.[0]?.["affiliation-country"] || 'Unknown Country'}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAutoAdd(item)}
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: '8px',
                                                    padding: '6px 16px',
                                                    boxShadow: 2,
                                                }}
                                            >
                                                ADD
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))) : (publicationsGoogle.length > 0 && selectType == null) ? publicationsGoogle.map((item, index) => (
                            <ListItem key={index}>
                                <Card sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                            component="div"
                                            sx={{ fontWeight: 'bold' }}>
                                            {item.title}
                                        </Typography>
                                        <Typography>
                                            {item.authors + " " + item.journalInfo}
                                        </Typography>
                                    </CardContent>
                                    <CardActions >
                                        <LoadingButton loading={loading} startIcon={<Add />} onClick={() => handleAddGoogle(item.link)} variant='contained'>
                                            Add
                                        </LoadingButton>
                                    </CardActions>
                                </Card>
                            </ListItem>
                        )) : (<Stack spacing={2}>
                            <Typography>
                                Select publication type
                            </Typography>
                            <Select
                                defaultValue=""
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Publication Type' }}
                                value={selectType}
                                onChange={(e) => setSelectType(e.target.value)}
                                fullWidth
                            >
                                {publicationsType.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <LoadingButton loading={loading} startIcon={<Add />} onClick={endSave} variant='contained'>
                                Save
                            </LoadingButton>

                        </Stack>)}
                    </List>
                </DialogContent>

            </Dialog>
        </Box >
    );
};

// Component for Basic Search
const BasicSearch = ({ searchValue, onSearchValueChange, onSearch, loading }) => (
    <Grid container spacing={1}>
        <Grid item xs={10}>
            <TextField
                size="small"
                fullWidth
                placeholder="Search scopus (Title, abstract, DOI, author)"
                value={searchValue}
                onChange={onSearchValueChange}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onSearch();
                }}
            />
        </Grid>
        <Grid item xs={2}>
            <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={24} /> : <Search />}
                fullWidth
                onClick={onSearch}
                disabled={loading}
            >
                {loading ? 'Searching...' : 'Search'}
            </Button>
        </Grid>
    </Grid>
);

// Component for Advanced Search
const AdvancedSearch = ({ searchType, searchValue, onSearchTypeChange, onSearchValueChange, onSearch }) => (
    <>
        <Grid item xs={12} sm={3}>
            <Select
                size="small"
                value={searchType}
                onChange={onSearchTypeChange}
                fullWidth
            >
                <MenuItem value="DOI">DOI</MenuItem>
                <MenuItem value="Author Name">Author Name</MenuItem>
            </Select>
        </Grid>
        <Grid item xs={12} sm={9}>
            <TextField
                size="small"
                fullWidth
                placeholder={`Search by ${searchType}`}
                value={searchValue}
                onChange={onSearchValueChange}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onSearch();
                }}
            />
        </Grid>
    </>
);

export default SearchAutoPublication;
