import { Paper, Stack, useMediaQuery, Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const baseUrl = process.env.REACT_APP_BASE_URL;

const PieChartBox = ({ publicStatus = false }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [data, setData] = useState([]);
    const { userId = 1 } = useParams(); // Default userId if not provided
    const { token } = useUserContext();

    const fetchData = async (url, isAuthRequired = false) => {
        try {
            const config = isAuthRequired
                ? { headers: { Authorization: `Bearer ${token}` } }
                : {};
            const response = await axios.get(url, config);
            console.log(response)
            const formattedData = [
                { id: 0, value: response.data.articleCount || 0, label: "Article" },
                { id: 1, value: response.data.thesisCount || 0, label: "Thesis" },
                { id: 2, value: response.data.chapterInABook || 0, label: "Chapter in a book" },
                { id: 3, value: response.data.conferencePaperCount || 0, label: "Conference paper" },
                { id: 4, value: response.data.researchProjectCount || 0, label: "Research project" },
                { id: 5, value: response.data.companyTestReportCount || 0, label: "Company test report" },
                { id: 7, value: response.data.letterCount || 0, label: "Letter" },
                { id: 8, value: response.data.editorialMaterialCount || 0, label: "Editorial material" },
                { id: 9, value: response.data.retractedArticle || 0, label: "Retracted article" },
                ...(response.data.bookCount
                    ? [{ id: 6, value: response.data.bookCount, label: "Book" }]
                    : []),
            ];
            setData(formattedData);
        } catch (error) {
            console.error("Error fetching publication counts:", error);
        }
    };

    useEffect(() => {
        const url = publicStatus
            ? `${baseUrl}/api/v1/no-auth/user/${userId}/publication-counts`
            : `${baseUrl}/api/v1/user/${userId}/publication-counts`;

        fetchData(url, !publicStatus); // Fetch with auth if not public
    }, [userId, publicStatus, token]);

    const pieChartData = [
        {
            data,
            outerRadius: 100,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            cx: 110,
            label: false,
        },
    ];

    return (
        <Paper sx={{ borderRadius: 3, p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: isMobile ? '100%' : '400px',
                    }}
                >
                    <PieChart
                        series={pieChartData}
                        height={200}
                        width={isMobile ? 200 : 300}
                        slotProps={{
                            legend: {
                                position: 'bottom', // Move legend below the chart
                                hidden: true, // Hide legend on mobile
                                layout: 'horizontal', // Align legend horizontally
                            },
                        }}
                    />
                </Box>
            </Stack>
        </Paper>
    );
};

export default PieChartBox;
