import { Container, Grid, Stack } from '@mui/material'
import React from 'react'
import PublicationPage from './middlePage/PublicationPage'

const LibraryPage = () => {
    return (
        <Container>
            <Grid container pt={2}>
                <PublicationPage library={true}/>
            </Grid>
        </Container>

    )
}

export default LibraryPage
