import React, { useEffect, useState } from 'react';
import { MenuItem, Select, ListItemIcon, ListItemText, Paper, Stack, Typography, Button, Grid, Divider, useMediaQuery, useTheme, Box, Dialog, DialogActions, DialogContent, DialogTitle, Badge, Chip, CircularProgress, List, ListItem, IconButton, ListItemButton, TextField } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Add, AddCircleOutline, ArrowBack, ArrowRight, AutoAwesome, Autorenew, Close, Edit, ErrorOutline, MailOutline, MenuBook, Science } from '@mui/icons-material';
import { useNewPublicationContext } from '../../context/NewPublicationProvider';
import { useUserContext } from '../../auth/AuthProvider';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import SearchAutoPublication from './SearchAutoPublication';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectPublicationType = () => {
    const publications = [
        { id: 0, key: "Article", value: "Article", icon: <ArticleIcon /> },
        { id: 1, key: "Thesis", value: "Thesis", icon: <SchoolIcon /> },
        { id: 2, key: "Chapter in a book", value: "Chapter in a book", icon: <BookIcon /> },
        { id: 3, key: "Conference paper", value: "Conference paper", icon: <AssignmentIcon /> },
        { id: 4, key: "Research project", value: "Research project", icon: <Science /> },
        { id: 5, key: "Company test report", value: "Company test report", icon: <BusinessCenterIcon /> },
        { id: 6, key: "Book", value: "Book", icon: <MenuBook /> },
        { id: 7, key: "Letter", value: "Letter", icon: <MailOutline /> },
        { id: 8, key: "Retracted Article", value: "Retracted Article", icon: <ErrorOutline /> },  // Letter icon added here
        { id: 9, key: "Editorial Material", value: "Editorial Material", icon: <Edit /> }  // Letter icon added here
    ];

    const { setStep, updateData, updateType, publication } = useNewPublicationContext();
    const [publicationUploadType, setPublicationUploadType] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false); // New loading state
    const { token } = useUserContext();
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Fetch publications on component mount





    const handleChange = (value) => {
        if (value === publication.type) {
            updateData({});
            updateType("");
        } else {
            updateType(value);
            updateData({});
        }
    };


    return (
        <Paper elevation={3} sx={{ borderRadius: 3 }}>
            <Stack p={2} spacing={2}>
                <Stack direction="row">
                    <Stack>
                        <Typography color="primary.main" variant='h4'><b>New publications</b></Typography>
                        {publicationUploadType === "manuel" && <Typography variant='h6'>
                            First, carefully select the type of publication you wish to upload, and then click the 'Continue' button to proceed to the next step.
                        </Typography>}
                        {publicationUploadType === "auto" && <Typography variant='h6'>
                            Please enter your researcher IDs into your profile and let us find your articles.
                        </Typography>}
                        <Divider />
                    </Stack>

                </Stack>

                {
                    publicationUploadType ? (
                        publicationUploadType === "auto" ? (
                            <>
                                <Box>
                                    <Button color='error' variant='outlined' startIcon={<ArrowBack />} onClick={() => setPublicationUploadType(null)}>Back</Button>
                                </Box>
                                <SearchAutoPublication />
                            </>

                        ) : publicationUploadType === "manuel" ? (
                            <>
                                <Box>
                                    <Button color='error' variant='outlined' startIcon={<ArrowBack />} onClick={() => setPublicationUploadType(null)}>Back</Button>
                                </Box>

                                <Grid container spacing={1}>
                                    {publications.map((publicationItem) => (
                                        <Grid item xs={12} sm={6} md={6} key={publicationItem.id}>
                                            <Button
                                                sx={{ border: publicationItem.value === publication.type ? "2px solid" : "none" }}
                                                onClick={() => handleChange(publicationItem.value)}
                                                fullWidth
                                                startIcon={publicationItem.icon}
                                            >
                                                {publicationItem.value}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>

                        ) : (
                            <></>
                        )
                    ) : (
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2} // Düğmeler arasında boşluk
                            sx={{ marginTop: 2 }} // Yukarıdan boşluk
                        >
                            <Button
                                fullWidth
                                size='large'
                                variant="contained" // Daha modern bir görünüm
                                onClick={() => setPublicationUploadType("manuel")}
                                color="primary"
                                startIcon={<AddCircleOutline />} // İkon ekledik
                                sx={{
                                    textTransform: "capitalize", // Düğme metnini küçük/büyük harf kontrolü
                                    padding: "10px 20px",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    "&:hover": { // Hover efekti
                                        backgroundColor: "primary.dark",
                                    },
                                }}
                            >
                                Manual Add Publication
                            </Button>
                            <Button
                                fullWidth
                                size='large'
                                variant="outlined" // Farklı bir stil
                                onClick={() => setPublicationUploadType("auto")}
                                color="secondary"
                                startIcon={<AutoAwesome />} // İkon ekledik
                                sx={{
                                    textTransform: "capitalize",
                                    padding: "10px 20px",
                                    "&:hover": {
                                        backgroundColor: "secondary.light",
                                        borderColor: "secondary.main",
                                    },
                                }}
                            >
                                Auto Add Publication
                            </Button>
                        </Stack>
                    )
                }


                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        disabled={publication.type === ""}
                        onClick={() => setStep(1)}
                        startIcon={<ArrowRight />}
                        variant='contained'
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>


        </Paper>
    );
};

export default SelectPublicationType;
